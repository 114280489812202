
// @ is an alias to /src
import { defineComponent } from "vue";
// import { RepositoryFactory, AuthRepository } from "@/lib/https";
// import { storage, key } from "@/lib/storage";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "Login",
  components: {
  },

  props: {
  },

  methods: {
  },

  setup() {
    // const router = useRouter();
  },
});
